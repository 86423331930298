import { render, staticRenderFns } from "./VideoSectionViewer.vue?vue&type=template&id=09310900&scoped=true"
import script from "./VideoSectionViewer.vue?vue&type=script&lang=js"
export * from "./VideoSectionViewer.vue?vue&type=script&lang=js"
import style0 from "./VideoSectionViewer.vue?vue&type=style&index=0&id=09310900&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09310900",
  null
  
)

export default component.exports